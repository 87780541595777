import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import {graphql, StaticQuery} from 'gatsby'
import { Link } from "gatsby"

function escapeRegExp(str) {
  return str.replace(/[-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
}

function clean($input){
  var $find = `href="https://stirtingale.uk/platocms/`;
  var $replace = `href="`;
  return $input.replace(new RegExp(escapeRegExp($find), 'g'), $replace);
  return $input;
}

function customseo_title($customseo_input,$page_title){
  return $customseo_input.length ? $customseo_input : $page_title;
}

const IndexPage = () => (
  <Layout>
    <StaticQuery query={graphql`
    {
    allWordpressPage(filter: {wordpress_id: {eq: 39}}) {
      edges {
        node {
            id
            title
            content
            acf {
              custom_title
              custom_description
            }
        }
      }
    }
    }
      `} 
    render={props => (
        <>

          {props.allWordpressPage.edges.map(page=> (
              <div key={page.node.id} >
              <SEO 
              title={customseo_title(page.node.acf.custom_title,page.node.title)}
              description={page.node.acf.custom_description}
              />
              <div 
              dangerouslySetInnerHTML={{__html:clean(page.node.content)}}
              />
            </div>
          ))}
        </>
      )} />
  </Layout>
)

export default IndexPage
